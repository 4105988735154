import React, { useEffect, useState } from 'react';
import GlobalLayout from '../layouts/globalLayout';
import * as styles from './shop.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import EmailSubscribeForm from '../components/EmailSubscribeForm/emailSubscribeForm';
import Chart from 'react-google-charts';

const imagesQuery = graphql`{
    headerPic: file(relativePath: {eq: "shop/HeaderImage.jpeg"}) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
      }
    },
    candlesPic: file(relativePath: {eq: "shop/Candles.jpeg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    scentsPic: file(relativePath: {eq: "shop/Scents.jpeg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    learnMorePic: file(relativePath: {eq: "shop/LearnMore.jpeg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    rainbowBackgroundPic: file(relativePath: {eq: "shop/rainbowBackground.jpg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    meetCuteBoxPic: file(relativePath: {eq: "shop/meetCuteBox.jpg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    squiggleIcon: file(relativePath: {eq: "icons/long_squiggle-01.png"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
    nftCollage: file(relativePath: {eq: "shop/NFTCollage.png"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
        }
    },
}`;

const scentNames = [
    "Mama's Boy", 
    "Bad Boy", 
    "Magical Mind", 
    "Girl Next Door", 
    "Hiking Buddy", 
    "Witchy Woman", 
    "Kindred Spirit"
];
const scentDescriptions = [
    "Cotton Blossom, Musk, Cedarwood", 
    "Sage, Citrus, Oakmoss, Amber",
    "Hydrangea, Apple Blossom, Grapefruit",
    "Lemon, Agave Nectar, Citrus",
    "Rain, Lavender, Moss, Driftwood",  
    "Sandalwood, Cardamom, Palo Santo",
    "Orange Blossom, Chrysanthemum, Grass"
]

export default function Shop() {
    const images = useStaticQuery(imagesQuery);
    const [isSwatchInView, setIsSwatchInView] = useState(false);
    const [colorSelected, setColorSelected] = useState(4);
    const isSmallScreen = useMediaQuery("(max-width: 500px)");

    useEffect(() => {
        if (IntersectionObserver) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsSwatchInView(true);
                    }
                });
            });
    
            observer.observe(document.querySelector('#scentsSwatch'));
        } else {
            setIsSwatchInView(true);
        }
        
    }, []);

    return (
        <GlobalLayout>
            <GatsbyImage alt="variety of candles" image={images.headerPic.childImageSharp.gatsbyImageData} className={styles.headerImage} />
            <div className={styles.pageContent}>
                <div className={styles.headerTextContainer}>
                    <h1 className={styles.headerText}>
                        because everyday deserves a little vintage magic
                    </h1>
                </div>
                <div className={styles.buttonContainer}>
                    <Link to="/shop/products" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.candlesPic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>candles</h1>
                    </Link>
                    <Link to="/shop#scents" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.scentsPic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>scents</h1>
                    </Link>
                    <Link to="/shop#learnmore" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.learnMorePic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>learn more</h1>
                    </Link>
                </div>
                <div className={styles.spacer} />
                <div id="scents" className={styles.scentsContainer}>
                    <h1 className={styles.scentsHeader}>
                        OUR SCENTS
                    </h1>
                    <h2 className={styles.scentsSubHeader}>
                        Who will you bring back to the love shack?
                    </h2>
                    <div className={clsx(styles.scentsDescription, styles[`color${colorSelected}`])}>
                        <h2 className={styles.scentName}>{scentNames[colorSelected - 1]}</h2>
                        <p className={styles.scentDescription}>{scentDescriptions[colorSelected - 1]}</p>
                        <Link to="/shop/products" className={styles.candleLink}>
                            <Button variant="outlined" className={clsx(
                                styles.candleButton,
                                {
                                    [styles.color1]: colorSelected === 1,
                                    [styles.color2]: colorSelected === 2,
                                    [styles.color3]: colorSelected === 3,
                                    [styles.color4]: colorSelected === 4,
                                    [styles.color5]: colorSelected === 5,
                                    [styles.color6]: colorSelected === 6,
                                    [styles.color7]: colorSelected === 7,
                                }
                            )}>
                                View Candles
                            </Button>
                        </Link>
                    </div>
                    <div id="scentsSwatch" className={styles.scentsSwatch}>
                        {isSmallScreen ? 
                        (
                            <Chart 
                                width={'100%'}
                                height={'100%'}
                                chartType="PieChart"
                                loader={<div></div>}
                                data={[
                                    ['Scent', 'Nothing'],
                                    ...scentNames.map(scent => [scent, 10])
                                ]}
                                options={{
                                    backgroundColor: 'transparent',
                                    chartArea: {
                                        left: '3%',
                                        top: 0,
                                        width: '94%',
                                        height: '94%'
                                    },
                                    legend: 'none',
                                    pieSliceText: 'label',
                                    pieStartAngle: (51.42 * 3) + (51.42 / 2),
                                    fontName: 'Bergen',
                                    tooltip: {
                                        trigger: 'none'
                                    },
                                    animation: {
                                        duration: 1000,
                                        easing: 'out'
                                    },
                                    slices: {
                                        0: {
                                            color: '#994e2e',
                                            offset: colorSelected === 1 ? 0.1 : 0,
                                            textStyle: {
                                                color: 'white'
                                            }
                                        },
                                        1: {
                                            color: '#f3c879',
                                            offset: colorSelected === 2 ? 0.1 : 0,
                                            textStyle: {
                                                color: '#994e2e'
                                            }
                                        },
                                        2: {
                                            color: '#f1b18e',
                                            offset: colorSelected === 3 ? 0.1 : 0,
                                            textStyle: {
                                                color: '#994e2e'
                                            }
                                        },
                                        3: {
                                            color: '#f2d1c7',
                                            offset: colorSelected === 4 ? 0.1 : 0,
                                            textStyle: {
                                                color: '#994e2e'
                                            }
                                        },
                                        4: {
                                            color: '#da6b46',
                                            offset: colorSelected === 5 ? 0.1 : 0,
                                            textStyle: {
                                                color: 'white'
                                            }
                                        },
                                        5: {
                                            color: '#99261d',
                                            offset: colorSelected === 6 ? 0.1 : 0,
                                            textStyle: {
                                                color: 'white'
                                            }
                                        },
                                        6: {
                                            color: '#f4e9d7',
                                            offset: colorSelected === 7 ? 0.1 : 0,
                                            textStyle: {
                                                color: '#994e2e'
                                            }
                                        }
                                    }
                                }}
                                rootProps={{ 'data-testid': '1' }}  
                                chartEvents={[{
                                    eventName: 'select',
                                    callback({ chartWrapper }) {
                                        const selection = chartWrapper.getChart().getSelection();
                                        
                                        if (selection && selection.length > 0) {
                                            setColorSelected(chartWrapper.getChart().getSelection()[0].row + 1);
                                        }
                                    }
                                }]}
                            />) 
                            : 
                            [1, 2, 3, 4, 5, 6, 7].map((num) => (
                            <div key={num} id={`swatch${num}`} className={clsx(
                                styles[`color${num}`],
                                styles.swatch,
                                {
                                    [styles[`swatch${num}Animation`]]: isSwatchInView,
                                    [styles[`swatchActive`]]: colorSelected === num
                                }
                            )} 
                                onClick={() => { setColorSelected(num); }}
                            >
                                {scentNames[num - 1]}
                            </div>
                        ))}
                    </div>
                    <div className={styles.meetCuteContainer}>
                        <div className={styles.meetCuteContainerLeft}>
                            <div className={styles.meetCuteLeftText}>
                                Each scent is one of a kind! They are designed to inspire feelings, take you back to happy memories, or encourage fresh new ones.
                            </div>
                        </div>
                        <div className={styles.meetCuteContainerRight}>
                            <Link to={`/shop/meet-cute-scent-kit`}>
                                <GatsbyImage 
                                    image={images.meetCuteBoxPic.childImageSharp.gatsbyImageData} 
                                    className={styles.meetCutePic} 
                                    style={{
                                        position: 'absolute'
                                    }}
                                />
                            </Link>
                            <div className={styles.meetCuteContainerRightText}>
                                <div className={styles.meetCuteTitle}>
                                    Not sure what scent to bring back to the love shack?
                                </div>
                                <Link to={`/shop/meet-cute-scent-kit`} className={styles.meetCuteLink}>
                                    <Button variant="outlined" className={styles.meetCuteButton}>
                                        SHOP MEET CUTE SCENT KIT
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p className={styles.scentSwatchDescription}>
                    </p>
                    
                </div>
                <div className={styles.spacer} />
                <div className={styles.subscribeContainer}>
                    <div className={styles.subscribeHeader}>stay groovy, baby!</div>
                    <div className={styles.subscribeSubHeader}>STAY UP TO DATE ON NEW ITEMS AND SALES</div>
                    <EmailSubscribeForm />
                    <GatsbyImage image={images.squiggleIcon.childImageSharp.gatsbyImageData} className={styles.squiggleIcon} />
                </div>
                <div className={styles.spacer} />
                <div id="learnmore" className={styles.learnMoreContainer}>
                    <h1 className={styles.learnMoreHeader}>
                        LEARN MORE
                    </h1>
                    <h2 className={styles.learnMoreSubheader}>
                        First Burn
                    </h2>
                    <p className={styles.learnMoreParagraph}>
                        Did you know that every candle has a memory? With each burn the candle creates a memory ring in the pool of wax. Creating a perfect memory ring starts with your first burn. Cut your wick to 1/4" and burn your candle for about 3 hours or until the wax pool melts to the edge of the vessel. This will ensure each subsequent burn pools to the outer edges and limits tunneling. Make sure you do not burn your candle for more than 4hrs.
                    </p>
                    <h2 className={styles.learnMoreSubheader}>
                        How to Repurpose your Vintage Vessel 
                    </h2>
                    <p className={styles.learnMoreParagraph}>
                        Once your candle has its last burn turn it into a beautiful piece of home decor by following these cleaning steps:          
                    </p>
                    <ol className={styles.learnMoreList}>
                        <li>fill the vessel with boiling water</li>
                        <li>wax will soften and float to the top </li>
                        <li>wait a few hours to let the wax solidify</li>
                        <li>pop the wax out and remove the wick</li>
                        <li>wash the vessel with hot soapy water</li>
                        <li>reuse as a candy dish, a planter, a vase, a catchall dish, or anything you can dream up</li>
                    </ol>
                    <h2 className={styles.learnMoreSubheader}>
                        How we source our vintage items
                    </h2>
                    <p className={styles.learnMoreParagraph}>
                        We live for treasure hunting! The thrill of  finding special vintage items makes our heart skip a beat.  We spend our free time visiting estate sales, thrift stores and online auctions to find the best vintage pieces out there! All of our items have been loved and treasured before we came across them. Turning old to new is our favorite thing to do. Thank you for being our kindred spirit and bringing life and light back into vintage.
                    </p>
                </div>
                <div className={styles.spacer} />
                <div className={styles.nftContainer}>
                    <h1 className={styles.nftHeader}>
                        MODERN MEETS VINTAGE
                    </h1>
                    <h2 className={styles.nftSubHeader}>
                        Free NFT with Purchase on Select Items
                    </h2>
                    <GatsbyImage image={images.nftCollage.childImageSharp.gatsbyImageData} className={styles.nftCollage} />
                    <p className={styles.nftDescription}>
                        Turning old to new is our favorite thing to do! We want to bring some vintage magic into the modern world of NFT. We are so excited to announce that with each purchase of a vintage item you will receive a free NFT. This NFT represents a digital artwork of your new vintage item that is unique and owned by you! Note these NFTs are not a contract of ownership for the physical item, but rather a fun extra gift to bring even more magic into each purchase.                    </p>
                    <p className={styles.nftDescription}>
                        Is your heart more in the vintage age and not caught up on the latest digital world? Read more about these groovy NFTs <Link to={`/nft`}>here</Link>. 
                    </p>
                    <Link to={`/blog/claim-your-free-nft`} className={styles.meetCuteLink}>
                        <Button variant="outlined" className={styles.meetCuteButton}>
                            CLAIM YOUR NFT
                        </Button>
                    </Link>
                </div>
            </div>
        </GlobalLayout>
    )
}