// extracted by mini-css-extract-plugin
export var headerImage = "shop-module--headerImage--2LuMd";
export var headerTextContainer = "shop-module--headerTextContainer--27TSh";
export var headerText = "shop-module--headerText--3NGKz";
export var buttonContainer = "shop-module--buttonContainer--1aIb5";
export var card = "shop-module--card--318X8";
export var cardPicture = "shop-module--cardPicture--3UEzC";
export var cardText = "shop-module--cardText--3-raO";
export var spacer = "shop-module--spacer--25_5f";
export var scentsContainer = "shop-module--scentsContainer--RQUVP";
export var scentsHeader = "shop-module--scentsHeader--tLOhv";
export var scentsSubHeader = "shop-module--scentsSubHeader--p5Cvr";
export var scentsDescription = "shop-module--scentsDescription--3WwQP";
export var scentName = "shop-module--scentName--1ser4";
export var scentDescription = "shop-module--scentDescription--3L5xq";
export var candleLink = "shop-module--candleLink--3s2e8";
export var candleButton = "shop-module--candleButton--2DgMS";
export var scentsSwatch = "shop-module--scentsSwatch--2CQog";
export var color1 = "shop-module--color1--1PzYf";
export var color2 = "shop-module--color2--2hWze";
export var color3 = "shop-module--color3--1lx4m";
export var color4 = "shop-module--color4--3UQc2";
export var color5 = "shop-module--color5--3OJuu";
export var color6 = "shop-module--color6--3pZ7a";
export var color7 = "shop-module--color7--3jnkw";
export var swatch = "shop-module--swatch--33e6t";
export var swatchActive = "shop-module--swatchActive--3Jk8d";
export var scentSwatchDescription = "shop-module--scentSwatchDescription--2l-Os";
export var meetCuteContainer = "shop-module--meetCuteContainer--1KUlD";
export var meetCuteContainerLeft = "shop-module--meetCuteContainerLeft--3SPX6";
export var meetCuteLeftText = "shop-module--meetCuteLeftText--1Jhg9";
export var meetCuteContainerRight = "shop-module--meetCuteContainerRight--2mHZ6";
export var meetCuteContainerRightText = "shop-module--meetCuteContainerRightText--13iUX";
export var meetCuteTitle = "shop-module--meetCuteTitle--3l2b8";
export var meetCutePic = "shop-module--meetCutePic--1v4kw";
export var meetCuteLink = "shop-module--meetCuteLink--3FLnI";
export var meetCuteButton = "shop-module--meetCuteButton--39fbf";
export var learnMoreContainer = "shop-module--learnMoreContainer--3N1Ge";
export var learnMoreHeader = "shop-module--learnMoreHeader--2WRpt";
export var learnMoreSubheader = "shop-module--learnMoreSubheader--1yBdG";
export var nftSubHeader = "shop-module--nftSubHeader--322c6";
export var learnMoreParagraph = "shop-module--learnMoreParagraph--1Gg_k";
export var nftDescription = "shop-module--nftDescription--3fdpH";
export var learnMoreList = "shop-module--learnMoreList--3c-QV";
export var subscribeContainer = "shop-module--subscribeContainer--1y5Sz";
export var nftContainer = "shop-module--nftContainer--2kc2V";
export var subscribeHeader = "shop-module--subscribeHeader--3E5Nt";
export var nftHeader = "shop-module--nftHeader--1Pnyk";
export var subscribeSubHeader = "shop-module--subscribeSubHeader--2d0sj";
export var squiggleIcon = "shop-module--squiggleIcon--2rkBh";
export var nftCollage = "shop-module--nftCollage--1tnj1";
export var swatch1Animation = "shop-module--swatch1Animation--3mahw";
export var swatch1Turn = "shop-module--swatch1-turn--NO3LE";
export var swatch2Animation = "shop-module--swatch2Animation--w5Djb";
export var swatch2Turn = "shop-module--swatch2-turn--1dTzp";
export var swatch3Animation = "shop-module--swatch3Animation--1oA6D";
export var swatch3Turn = "shop-module--swatch3-turn--32ZDC";
export var swatch4Animation = "shop-module--swatch4Animation--3I96M";
export var swatch4Turn = "shop-module--swatch4-turn--2CcMr";
export var swatch5Animation = "shop-module--swatch5Animation--1c_ew";
export var swatch5Turn = "shop-module--swatch5-turn--2Eu0c";
export var swatch6Animation = "shop-module--swatch6Animation--3BWf0";
export var swatch6Turn = "shop-module--swatch6-turn--373bd";
export var swatch7Animation = "shop-module--swatch7Animation--2guKk";
export var swatch7Turn = "shop-module--swatch7-turn--1fOAM";